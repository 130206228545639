import React from "react"
import Layout from "../../components/layout"
import Logo from "../../img/client-munky-logo.jpg"
import Web from "../../img/client-munky-web.jpg"

export default ({ location, data }) => {
  return (
    <Layout location={location}>
      <div className="cover-heading-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-10">
              <h1 className="cover-heading">MunkyTees</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="cover-wrapper cover-munkytees">
        <div className="cover-wrapper-inner">
          <div className="cover-container">
            <div className="inner cover"></div>
          </div>
        </div>
      </div>
      <section className="m-t-100 sm-m-t-20">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="">
                <h4>Client</h4>
                <p className="m-b-20">MunkyTees Pvt Ltd, Sri Lanka.</p>

                <h4>Roles</h4>
                <p className="m-b-20">
                  Brand Identity / Web Design &amp; Development
                </p>

                <p className="m-b-20">
                  <a rel="noopener" href="http://munkytees.com" target="_blank">
                    munkytees.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-7">
              <p>
                MunkyTees, a clothing brand for geeks, approached us to design
                and develop their online presence. We wanted it to look fun,
                energetic and creative.
              </p>
            </div>
          </div>

          <div className="shot m-t-50 sm-m-t-20 m-b-50 sm-m-b-20">
            <img alt="Mukytees logo" src={Logo} />
          </div>

          <div className="shot m-t-50 sm-m-t-20 m-b-50 sm-m-b-20">
            <img alt="Munkytees web" src={Web} />
          </div>
        </div>
      </section>
    </Layout>
  )
}
